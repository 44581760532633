import Event from './event'

function isExpiredToken (error) {
  return error.response?.status === 401
    && error.response?.data?.error === 'TokenExpiredError'
}

function isTokenAuthFailed (error) {
  return error.response.status === 401
    && error.response?.data?.message === 'Failed to authenticate token.'
}

function onFulfilled ({ axiosResponse }) {
  return axiosResponse
}

function onRejected ({ error }) {
  if (isExpiredToken(error) || isTokenAuthFailed(error)) {
    Event.dispatchEvent('tokenExpired')
  }
  return Promise.reject(error)
}

export default function AddRequestInterceptor ({ axios }) {
  axios.interceptors.response.use(
    axiosResponse => onFulfilled({ axios, axiosResponse }),
    error => onRejected({ error }),
  )
}
