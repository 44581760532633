export function notification (state) {
  return state.notification
}

export function isMenuVisible (state) {
  return state.menu
}

export function isDarkMode (state) {
  return state.dark
}

export function isDocumentMenuVisible (state) {
  return state.documentMenu
}

export function isMenuCollapsed (state) {
  return state.collapsedMenu
}

export function isSessionMenuVisible (state) {
  return state.sessionMenu
}

export function currentOperationTab (state) {
  return state.currentOperationTab
}

export function currentOperationProductionTab (state) {
  return state.currentOperationProductionTab
}

export function isUpdateMeDialogOpen (state) {
  return state.updateMeDialog
}

export function isSwitchUserDialogOpen (state) {
  return state.switchUserDialog
}

export function isCheckUserDialogOpen (state) {
  return state.checkUserDialog
}

export function partPerPage (state) {
  return type => {
    switch (type) {
      case 'Todo':
        return state.currentPartsPerPageTodoList
      case 'Done':
        return state.currentPartsPerPageDoneList
      case 'Suspended':
        return state.currentPartsPerPageSuspendedList
      default:
        break
    }
    return 0
  }
}
