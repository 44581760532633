import Token from './token'

function onFulfilled ({ axiosRequestConfig }) {
  const accessToken = Token.getAccessToken()
  if (accessToken) {
    axiosRequestConfig.headers.common.Authorization = `Bearer ${accessToken}`
  }

  return axiosRequestConfig
}

function onRejected ({ error }) {
  return Promise.reject(error)
}

export default function AddRequestInterceptor ({ axios }) {
  axios.interceptors.request.use(
    axiosRequestConfig => onFulfilled({ axios, axiosRequestConfig }),
    error => onRejected({ axios, error }),
  )
}
