/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import Repositories from 'src/repositories'
import Helpers from 'src/utils/helpers'
import moment from 'moment'
import { createManyRecordSimulation, updateManyRecordSimulation } from './backEndLocalSimulation'

export function setBatch ({ commit }, batch) {
  commit('SET_BATCH', batch)
}

export async function donePart ({ commit, dispatch, state }, params) {
  params.batch = Helpers.clone(state.batch)
  const batch = await createManyRecordSimulation(params)
  await commit('SET_BATCH', batch)
  await dispatch('setupBatchData')
}

export async function updatePart ({ commit, dispatch, state }, params) {
  params.batch = Helpers.clone(state.batch)
  const batch = await updateManyRecordSimulation(params)
  commit('SET_BATCH', batch)
  await dispatch('setupBatchData')
}

export async function deletePartList ({ commit, dispatch, state }, idList) {
  const batch = Helpers.clone(state.batch)
  batch.parts = batch.parts.filter(part => !idList.includes(part._id))
  commit('SET_BATCH', batch)
  await dispatch('setupBatchData')
}

export async function getBatch ({ commit, dispatch }, batchId) {
  try {
    commit('SET_LOADING', true)
    const response = await Repositories.batch.get(batchId)
    commit('SET_BATCH', response)
    await dispatch('setupBatchData')
  }
  catch (error) {
    dispatch('ui/showErrorNotification', Repositories.parseErrorMessage(error), { root: true })
  }
  finally {
    commit('SET_LOADING', false)
  }
}

function setupOperationData (batchOperation, batch) {
  if (batchOperation.operation.instructionsLevel) {
    batchOperation.instructionsRead = false
  }
  if (!batchOperation.operation.operationType.control) {
    batchOperation.todoList = batch.parts
      .filter(part => !part.suspended && !part.rejected && !part.destroyed)
      .filter(part => part.currentOperationType && part.currentOperationType._id.toString() === batchOperation.operation.operationType._id.toString())
  }
  else {
    batchOperation.todoList = batch.parts
      .filter(part => !part.doneOperationType.find(doneOperation => doneOperation.operationType === batchOperation.operation.operationType._id.toString()))
  }

  if (batchOperation.operation.operationType.isDelay) {
    batchOperation.availableTodoList = batchOperation.todoList.filter(part => moment(part.lastRecord).add(batchOperation.operation.operationType.delay, 'h') < moment())
  }

  batchOperation.doneList = batch.parts
    .filter(part => {
      if (part.doneOperationType.map(ope => ope.operationType.toString()).includes(batchOperation.operation.operationType._id.toString())) {
        return true
      }
      return false
    }, 0)
    .filter(part => {
      if (!part.suspended) return true
      let isSuspendedAtOperation = false
      for (const declaration of part.declarationData) {
        if (
          declaration.operationType._id.toString() === batchOperation.operation.operationType._id.toString()
          && (declaration.type === 'Quarantaine' || declaration.type === 'Décision en suspens')
        ) {
          isSuspendedAtOperation = true
        }
      }
      return !isSuspendedAtOperation
    })

  batchOperation.stats = batch.parts.reduce((prev, curr) => {
    for (const declaration of curr.declarationData) {
      if (declaration.operationType._id.toString() === batchOperation.operation.operationType._id.toString()) {
        if (declaration.type === 'Rebut') prev.rejectedList.push(curr)
        if (declaration.type === 'Quarantaine' && curr.suspended) {
          if (!curr.declarationData.find(decl => decl.operationType._id.toString() === batchOperation.operation.operationType._id.toString() && decl.type === 'Dérogation')) {
            prev.suspendedList.push(curr)
          }
        }
        if (declaration.type === 'Décision en suspens' && curr.controlSuspended) {
          if (!curr.declarationData.find(decl => decl.operationType._id.toString() === batchOperation.operation.operationType._id.toString() && decl.type === 'Dérogation')) {
            prev.controlSuspendedList.push(curr)
          }
        }
      }
    }
    return prev
  }, { rejectedList: [], suspendedList: [], controlSuspendedList: [] })

  batchOperation.BatchRecordRequired = batchOperation.operation.batchRecords.length > 0
  batchOperation.batchRecordTodo = !batchOperation.batchRecord && batchOperation.BatchRecordRequired
  batchOperation.canBeClose = (!batchOperation.todoList.length && !batchOperation.batchRecordTodo) || (batchOperation.operation.operationType.control && ((batchOperation.doneList.length > 0 || batchOperation.operation.partsRecords.length === 0) && !batchOperation.batchRecordTodo))
  batchOperation.informationToDisplay = (batchOperation.operation.instructions !== '' || batchOperation.operation.document !== null)

  return batchOperation
}

export async function setupBatchData ({ commit, state }) {
  try {
    const localBatch = Helpers.clone(state.batch)
    for (let batchOperation of localBatch.orderOperations) {
      batchOperation = setupOperationData(batchOperation, localBatch)
    }
    for (let batchOperation of localBatch.unorderOperations) {
      batchOperation = setupOperationData(batchOperation, localBatch)
    }
    commit('SET_BATCH', localBatch)
  }
  catch (error) {
    console.log(error)
  }
}
