import RepositoryService from 'src/services/repository'

const resource = 'auth'

function login ({ userLogin, password }) {
  return RepositoryService.base.post(`${resource}/login/atelier`, {
    userLogin,
    password,
  })
}

function updateMe (editUserData) {
  return RepositoryService.base.patch(`${resource}/me`, editUserData)
}

function get () {
  return RepositoryService.crud.get(resource, 'me')
}

export default {
  login,
  updateMe,
  get,
}
