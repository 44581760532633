import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginRoutes from 'src/modules/login/routes'
import BatchSelectionRoutes from 'src/modules/batchSelection/routes'
import OpenBatchRoutes from 'src/modules/openBatch/routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    ...LoginRoutes,
    ...BatchSelectionRoutes,
    ...OpenBatchRoutes,
  ],
})

export default router
