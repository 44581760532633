export default [
  {
    path: '/batch/:batchId',
    component: () => import('./containers/OpenBatchContainer'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'operation/:operationId',
        name: 'Operation',
        props: true,
        component: () => import('./operation/containers/OperationContainer'),
        children: [],
      },
    ],
  },
]
