export default [
  {
    path: '/',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'BatchList',
        component: () => import('./containers/BatchListContainer'),
        children: [
          {
            path: 'create',
            name: 'BatchCreate',
            component: () => import('./containers/BatchCreateContainer'),
          },
        ],
      },
    ],
  },
]
