import router from 'src/router'
import Repositories from 'src/repositories'
import AxiosAuthenticationToken from 'src/services/axios/authentication/token'
import AxiosAuthenticationEvent from 'src/services/axios/authentication/event'

export async function init ({ commit, getters }) {
  // Logout if token is invalid
  AxiosAuthenticationEvent.on('tokenExpired', () => {
    commit('LOGOUT')
    router.push('/login')
  })

  if (getters.isAuthenticated) {
    // Add user token to axios requests
    AxiosAuthenticationToken.set(getters.tokens)

    // Refresh user data on app init
    const user = await Repositories.me.get()
    commit('SET_USER', user)
  }
  else {
    router.push('/login')
  }
}

export async function refreshMe ({ commit }) {
  // Refresh user data on app init
  const user = await Repositories.me.get()
  commit('SET_USER', user)
  commit('SET_LAST_USER_VERIFICATION', new Date())
}

export async function login ({ dispatch, commit, getters }, credentials) {
  try {
    const response = await Repositories.me.login(credentials)

    const {
      accessToken,
      user,
    } = response.data

    AxiosAuthenticationToken.set({ accessToken })

    commit('LOGIN', {
      user,
      accessToken,
    })

    dispatch('ui/showSuccessNotification', `Bienvenue ${getters.fullname}`, { root: true })
    commit('SET_LAST_USER_VERIFICATION', new Date())

    router.push('/')
  }
  catch (error) {
    const notification = Repositories.parseErrorMessage(error)
    dispatch('ui/showErrorNotification', notification, { root: true })
    throw error
  }
}

export async function switchUser ({ dispatch, commit, getters }, credentials) {
  try {
    const response = await Repositories.me.login(credentials)

    const {
      accessToken,
      user,
    } = response.data

    AxiosAuthenticationToken.set({ accessToken })

    commit('LOGIN', {
      user,
      accessToken,
    })

    dispatch('ui/showSuccessNotification', `Bienvenue ${getters.fullname}`, { root: true })
    commit('SET_LAST_USER_VERIFICATION', new Date())
  }
  catch (error) {
    const notification = Repositories.parseErrorMessage(error)
    dispatch('ui/showErrorNotification', notification, { root: true })
    throw error
  }
}

export async function logout ({ commit }) {
  commit('LOGOUT')
  router.push('/login')
  commit('SET_LAST_USER_VERIFICATION', null)
}

export async function setLastUserVerification ({ commit }, value) {
  commit('SET_LAST_USER_VERIFICATION', value)
}
