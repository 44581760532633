import { toggle, set } from 'src/utils/vuex'

export const TOGGLE_DARK = toggle('dark')
export const TOGGLE_MENU = toggle('menu')
export const TOGGLE_DOCUMENT_MENU = toggle('documentMenu')
export const TOGGLE_COLLAPSED_MENU = toggle('collapsedMenu')
export const SET_MENU = set('menu')
export const TOGGLE_SESSION_MENU = toggle('sessionMenu')
export const SET_SESSION_MENU = set('sessionMenu')
export const SET_CURRENT_OPERATION_TAB = set('currentOperationTab')
export const SET_CURRENT_OPERATION_PRODUCTION_TAB = set('currentOperationProductionTab')
export const SET_CURRENT_PARTS_PER_PAGE_DONE_LIST = set('currentPartsPerPageDoneList')
export const SET_CURRENT_PARTS_PER_PAGE_TODO_LIST = set('currentPartsPerPageTodoList')
export const SET_CURRENT_PARTS_PER_PAGE_SUSPENDED_LIST = set('currentPartsPerPageSuspendedList')
export const SET_UPDATE_ME_DIALOG = set('updateMeDialog')
export const SET_SWITCH_USER_DIALOG = set('switchUserDialog')
export const SET_CHECK_USER_DIALOG = set('checkUserDialog')

export function SHOW_NOTIFICATION (state, { success, message }) {
  state.notification = { visible: true, success, message }
}

export function HIDE_NOTIFICATION (state) {
  state.notification = {
    visible: false,
    message: '',
    success: state?.notification?.success,
  }
}
