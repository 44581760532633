<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters('me', {
      isAuthenticated: 'isAuthenticated',
      lastUserVerification: 'lastUserVerification',
    }),
    ...mapGetters('ui', {
      isDarkMode: 'isDarkMode',
    }),
  },
  mounted () {
    this.$store.dispatch('me/init')
    this.$vuetify.theme.dark = this.isDarkMode
    const self = this
    setTimeout(self.userVerfication(), 3000)
    this.timer = setInterval(() => {
      self.userVerfication()
    }, 20000)
  },
  methods: {
    ...mapActions('ui', {
      setCheckUserDialog: 'setCheckUserDialog',
    }),
    userVerfication () {
      if (this.isAuthenticated) {
        if (this.lastUserVerification && this.moment(this.lastUserVerification).add(120, 'm') < this.moment()) {
          this.setCheckUserDialog(true)
        }
      }
    },
  },
}
</script>
<style>
.v-data-table__checkbox .v-icon {
  color: #27579ec4 !important;
}
.data-table__selected {
  background-color: #d8d8d8 !important;
}
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>
tr.exception:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #fff7ae !important;
}
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>
tr.retouched:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #aed4ff !important;
}
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>
tr.rejected:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #ffaeae !important;
}
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>
tr.destroyed:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #b7b7b7 !important;
}
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>
tr.controlSuspended:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #ffbf8a !important;
}

.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>
tr.exception:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #d2c22d !important;
}
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>
tr.retouched:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #67a2e4 !important;
}
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>
tr.rejected:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #e46767 !important;
}
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>
tr.destroyed:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #b7b7b7 !important;
}
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>
tr.controlSuspended:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #e4a067 !important;
}

.theme--light .exception{
  background-color: #fff7ae;
}
.theme--light .retouched{
  background-color: #aed4ff;
}
.theme--light .rejected{
  background-color: #ffaeae;
}
.theme--light .destroyed{
  background-color: #b7b7b7;
}
.theme--light .suspended{
  background-color: #ffbf8a;
}
.theme--light .controlSuspended{
  background-color: #ffbf8a;
}

.theme--dark .exception{
  background-color: #d2c22d;
}
.theme--dark .retouched{
  background-color: #67a2e4;
}
.theme--dark .rejected{
  background-color: #e46767;
}
.theme--dark .destroyed{
  background-color: #b7b7b7;
}
.theme--dark .suspended{
  background-color: #e4a067;
}
.theme--dark .controlSuspended{
  background-color: #e4a067;
}

.theme--dark.v-card {
  background-color: #272727 !important;
}
.theme--dark.v-data-table {
    background-color: #363636 !important;
    color: #fff;
}
.theme--dark .v-data-table__checkbox .v-icon {
    color: #ffffffc4 !important;
}
.theme--dark.v-application .accent--text {
    color: #fff !important;
    caret-color: #fff !important;
}
.theme--dark.v-application .accent--text {
    color: #fff !important;
    caret-color: #fff !important;
}
</style>
