export function batch (state) {
  return state.batch
}

export function batchOperation (state) {
  return operationId => {
    let res = state.batch.orderOperations.find(ope => ope._id === operationId)
    if (!res) res = state.batch.unorderOperations.find(ope => ope._id === operationId)
    return res
  }
}

export function filtrationSurface (state) {
  return state.batch.partNumber.filtrationSurface || 0
}

export function loading (state) {
  return state.loading
}
