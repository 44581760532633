export default class Listeners {
  constructor () {
    this.listeners = {}
  }

  on (type, listener, context) {
    if (!this.listeners[type]) {
      this.listeners[type] = []
    }

    this.listeners[type].push({ listener, context })
  }

  un (type, listener, context) {
    if (type && this.listeners[type]) {
      if (listener) {
        this.listeners[type] = this.listeners[type]
          .filter(item => item.listener !== listener && (!context || item.context !== context))
      }
      else {
        this.listeners[type] = []
      }
    }
  }

  dispatchEvent (type, ...args) {
    if (this.listeners[type]) {
      this.listeners[type].forEach(({ listener, context }) => {
        listener.call(context, ...args)
      })
    }
  }
}
