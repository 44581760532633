import Axios from 'axios'
import Config from '../../config.json'
import AxiosAuthentication from './authentication'

const axios = Axios.create({
  baseURL: Config.API_URL,
})

AxiosAuthentication({ axios })

/**
 * @fires tokenRefreshSuccessful
 * @fires tokenRefreshFailed
 */
export default axios
