import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import createMediator from './mediator-plugin'

import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...modules,
  },
  plugins: [
    createPersistedState({
      key: 'atelier-polymem',
      paths: ['me', 'ui'],
      storage: window.sessionStorage,
    }),
    createMediator({
      listeners: [],
    }),
  ],
})
