/* eslint-disable no-loop-func */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */

async function updateRecords (parts, records) {
  try {
    let retouchedCancel = false
    /**
     * Suppression des déclaration crée pour l'opération
     */
    for (let i = 0; i < parts.length; i++) {
      parts[i].declarationData.forEach((declaration, index) => {
        if (declaration.operationType._id.toString() === records[0].operationType) {
          switch (declaration.type) {
            case 'Rebut':
              parts[i].rejected = false
              parts[i].rejectedAt = undefined
              break
            case 'Dérogation':
              parts[i].exception = false
              parts[i].exceptionAt = undefined
              break
            case 'Retouche':
              parts[i].retouched = false
              parts[i].retouchedAt = undefined
              retouchedCancel = true
              break

            default:
              break
          }
          parts[i].declarationData.splice(index, 1)
        }
      })
    }

    let partDesctruction = false
    for (const record of records) {
      if (record.destruction) { partDesctruction = true }
    }

    return [partDesctruction, retouchedCancel]
  }
  catch (error) {
    throw new Error(error)
  }
}

async function createRecords (
  recordList,
) {
  try {
    let partDesctruction = false
    for (const record of recordList) {
      if (record.destruction) { partDesctruction = true }
    }
    return partDesctruction
  }
  catch (error) {
    throw new Error(error)
  }
}

async function updatePartAndOperationStatus (
  operationBatchId,
  decision,
  batch,
  partList,
  partsFinished,
  batchType,
  partDesctruction,
  updatePart,
) {
  let finishedModules = false
  let currentOperationType = null
  let doneOperationType = null
  let movePartToNextAlterationOperation = false
  let orderOperation = true
  let openOperations = 0
  let alterationAllowed = false

  if (decision !== null && decision.value === 3) { movePartToNextAlterationOperation = true }

  batch.orderOperations.forEach((operation, i) => {
    if (operation._id.toString() === operationBatchId) {
      alterationAllowed = operation.operation.operationType.alterationAllowed
      if (batchType) {
        operation.batchRecord = true
      }
      else {
        doneOperationType = operation.operation.operationType
        currentOperationType = operation.operation.operationType
      }
      // Si la dernière opération est réalisée ont positionne la pièce à null
      if (i + 1 === batch.orderOperations.length) {
        currentOperationType = null
        // Si c'est un module on le concidère finis
        if (batch.partType.toString() === '5b4c8cb61d33360c2526d87f') {
          finishedModules = true
        }
      }

      for (let index = i + 1; index < batch.orderOperations.length; index++) {
        partsFinished = false
        if (
          !batchType
          && !movePartToNextAlterationOperation
          && (
            batch.orderOperations[index].operation.partsRecords.length > 0
            && !batch.orderOperations[index].operation.operationType.alteration
          )
        ) {
          batch.orderOperations[index].status = 1
          currentOperationType = batch.orderOperations[index].operation.operationType
          break
        }
        else if (
          !batchType
          && movePartToNextAlterationOperation
          && (
            batch.orderOperations[index].operation.partsRecords.length > 0
            && batch.orderOperations[index].operation.operationType.alteration
          )
        ) {
          batch.orderOperations[index].status = 1
          currentOperationType = batch.orderOperations[index].operation.operationType
          break
        }
      }
    }
    if (operation.status > 0) openOperations++
  })

  batch.unorderOperations.forEach(async operation => {
    // Permet de ne pas rendre bloquant la validation des opérations parrallèles
    // if (operation.status < 2) partsFinished = false
    if (operation._id.toString() === operationBatchId) {
      partsFinished = false
      if (batchType) {
        operation.batchRecord = true
      }
      doneOperationType = operation.operation.operationType
      orderOperation = false
    }
    if (operation.status > 0) openOperations++
  })

  if (!batchType && updatePart) {
    if (partDesctruction) {
      for (const part of partList) {
        part.destroyed = true
        part.destroyedAt = Date.now()
        part.doneOperationType.push({ doneAt: Date.now(), operationType: doneOperationType._id.toString() })
      }
    }
    else if (orderOperation) {
      for (const part of partList) {
        part.currentOperationType = currentOperationType
        part.lastRecord = Date.now()
        part.doneOperationType.push({ doneAt: Date.now(), operationType: doneOperationType._id.toString() })
      }
    }
    else {
      for (const part of partList) {
        part.doneOperationType.push({ doneAt: Date.now(), operationType: doneOperationType._id.toString() })
      }
    }
  }
  else if (!batchType && !updatePart) {
    if (partDesctruction) {
      for (const part of partList) {
        part.destroyed = true
        part.destroyedAt = Date.now()
      }
    }
    else if (orderOperation && alterationAllowed) {
      for (const part of partList) {
        part.currentOperationType = currentOperationType
      }
    }
  }
  // Pour les modules finis, on les notes utilisés car il n'y a pas de gestion de stock
  if (finishedModules) {
    for (const part of partList) {
      part.use = true
      part.destroyedAt = Date.now()
    }
  }
  batch.progressionBuffer = (openOperations / (batch.orderOperations.length) + batch.unorderOperations.length) * 100
  // await batch.save()
  return partsFinished
}

async function setDeclaration (operationType, decision, partList, batchType, user) {
  const from = batchType ? 'batchRecord' : 'partRecords'
  if (decision !== null) {
    switch (decision.value) {
      case 1:
        // Création du rebut
        for (const part of partList) {
          part.rejected = true
          part.rejectedAt = Date.now()
          part.declarationData.push({
            user, from, type: 'Rebut', declareAt: Date.now(), rejectionCauses: decision.cause, justification: decision.justification, operationType,
          })
        }
        break
      case 2:
        // Création de l'exception
        for (const part of partList) {
          part.exception = true
          part.declarationData.push({
            user, from, type: 'Dérogation', declareAt: Date.now(), rejectionCauses: decision.cause, justification: decision.justification, operationType,
          })
        }
        break
      case 3:
        // Création de la retouche
        for (const part of partList) {
          part.retouched = true
          part.declarationData.push({
            user, from, type: 'Retouche', declareAt: Date.now(), rejectionCauses: decision.cause, justification: decision.justification, operationType,
          })
        }
        break
      case 4:
        // Création de la quarantaine
        for (const part of partList) {
          part.suspended = true
          part.declarationData.push({
            user, from, type: 'Quarantaine', declareAt: Date.now(), rejectionCauses: decision.cause, justification: '', operationType,
          })
        }
        break
      case 5:
        // Création de la quarantaine
        for (const part of partList) {
          part.controlSuspended = true
          part.declarationData.push({
            user, from, type: 'Décision en suspens', declareAt: Date.now(), rejectionCauses: decision.cause, justification: '', operationType,
          })
        }
        break

      default:
        break
    }
  }

  return true
}

export async function createManyRecordSimulation ({
  batch, partIdList, recordList, decision, operationType, operationBatchId, batchTypeCreation,
}) {
  try {
    let partDesctruction = false
    let partsFinished = true

    const localPartList = partIdList !== null ? batch.parts.filter(part => partIdList.includes(part._id)) : batch.parts

    // Enregistrement des relevés
    partDesctruction = await createRecords(recordList)

    // Mise à jour des pièces et des status d'opérations
    partsFinished = await updatePartAndOperationStatus(operationBatchId, decision, batch, localPartList, partsFinished, batchTypeCreation, partDesctruction, true, false)

    if (decision !== null) await setDeclaration(operationType, decision, localPartList, batchTypeCreation, recordList[0].user)

    if (partsFinished) {
      for (const part of localPartList) {
        part.finish = true
        part.finishAt = Date.now()
      }
    }
    return batch
  }
  catch (error) {
    throw (new Error(error))
  }
}

export async function updateManyRecordSimulation ({
  batch, partIdList, recordList, decision, operationType, operationBatchId, batchTypeCreation,
}) {
  try {
    let partDesctruction = false
    let partsFinished = true
    let retouchedCancel = false

    const localPartList = partIdList !== null ? batch.parts.filter(part => partIdList.includes(part._id)) : batch.parts

    // Enregistrement des relevés
    const res = await updateRecords(localPartList, recordList)
    partDesctruction = res[0]
    retouchedCancel = res[1]

    // Mise à jour des pièces et des status d'opérations
    partsFinished = await updatePartAndOperationStatus(operationBatchId, decision, batch, localPartList, partsFinished, batchTypeCreation, partDesctruction, false, retouchedCancel)

    if (decision !== null) await setDeclaration(operationType, decision, localPartList, batchTypeCreation, recordList[0].user)

    if (partsFinished) {
      for (const part of localPartList) {
        part.finish = true
        part.finishAt = Date.now()
      }
    }

    return batch
  }
  catch (error) {
    throw (new Error(error))
  }
}
