import RepositoryService from 'src/services/repository'

const resource = 'record'

export default {
  ...RepositoryService.utils.createCrudRepository(resource),
  patchMany: params => RepositoryService.base.patch(`${resource}/many`, params),
  declarationForSuspendedPart: params => RepositoryService.base.post(`${resource}/declaration-suspended-part`, params),
  deleteMany: params => RepositoryService.base.simpleDelete(`${resource}`, params),
}
