import axiosInstance from 'src/services/axios'

function get (url, data) {
  return axiosInstance.get(url, { params: data })
}

function post (url, data, config) {
  return axiosInstance.post(url, data, config)
}

function patch (url, data, config) {
  return axiosInstance.patch(url, data, config)
}

function simpleDelete (url, data) {
  // ! Axios delete function ignore data in config object
  // ! Waiting for next axios release (> 0.20.0)
  // TODO: use standard delete when next release is published
  return axiosInstance.request({
    url,
    method: 'delete',
    data,
  })
  // return axiosInstance.delete(url, { data: { idList } })
}

function deleteMany (url, idList, options = {}) {
  // ! Axios delete function ignore data in config object
  // ! Waiting for next axios release (> 0.20.0)
  // TODO: use standard delete when next release is published
  return axiosInstance.request({
    url,
    method: 'delete',
    data: { idList, ...options },
  })
  // return axiosInstance.delete(url, { data: { idList } })
}

function deleteOne (url) {
  // ! Axios delete function ignore data in config object
  // ! Waiting for next axios release (> 0.20.0)
  // TODO: use standard delete when next release is published
  return axiosInstance.request({
    url,
    method: 'delete',
  })
  // return axiosInstance.delete(url, { data: { idList } })
}

export default {
  get,
  post,
  patch,
  deleteMany,
  deleteOne,
  simpleDelete,
}
