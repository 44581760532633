let accessToken = null

function getAccessToken () {
  return accessToken
}

function get () {
  return {
    accessToken: getAccessToken(),
  }
}

function setAccessToken (token) {
  accessToken = token ?? null
}

function set (tokens = {}) {
  if (tokens.accessToken !== undefined) {
    setAccessToken(tokens.accessToken)
  }
}

export default {
  get,
  set,
  getAccessToken,
  setAccessToken,
}
