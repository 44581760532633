import BaseRepository from './base-repository'

const AXIOS_FORMDATA_CONFIG = {
  'content-type': 'multipart/form-data',
}

function isFormDataRequired (data) {
  return Object.values(data).some(value => value instanceof File)
}

function getFormData (object) {
  return Object.entries(object).reduce((formData, [key, value]) => {
    formData.append(key, value)
    return formData
  }, new FormData())
}

export default {
  getList: async (resource, params) => {
    const response = await BaseRepository.get(`${resource}/`, params)
    return response?.data ?? response
  },
  get: async (resource, id) => {
    const response = await BaseRepository.get(`${resource}/${id}`)
    return response?.data ?? response
  },
  post: async (resource, data, { isFormData = false } = {}) => {
    const response = isFormData || isFormDataRequired(data)
      ? await BaseRepository.post(`${resource}/`, getFormData(data), AXIOS_FORMDATA_CONFIG)
      : await BaseRepository.post(`${resource}/`, data)
    return response?.data ?? response
  },
  patch: async (resource, { _id, ...data }, { isFormData = false } = {}) => {
    const response = isFormData || isFormDataRequired(data)
      ? await BaseRepository.patch(`${resource}/${_id}`, getFormData(data), AXIOS_FORMDATA_CONFIG)
      : await BaseRepository.patch(`${resource}/${_id}`, data)
    return response?.data ?? response
  },
  delete: async (resource, idList, options) => {
    const response = await BaseRepository.deleteMany(`${resource}/`, idList, options)
    return response?.data ?? response
  },
}
