export class Mediator {
  constructor (listeners = []) {
    this.MUTATION_LISTENERS = {}
    listeners.forEach(listener => {
      if (Array.isArray(listener.dispatch)) {
        this.subscribes(listener)
      }
      else {
        this.subscribe(listener)
      }
    })
  }

  /**
   * Trigger the action when the mutation is called
   *
   * @param {Object} options Options
   * @param {String} options.on Mutation to observe
   * @param {String} options.dispatch Action to dispatch
   */
  subscribe ({ on: mutationName, dispatch: actionName }) {
    if (!this.MUTATION_LISTENERS[mutationName]) {
      this.MUTATION_LISTENERS[mutationName] = []
    }

    if (!this.MUTATION_LISTENERS[mutationName].includes(actionName)) {
      this.MUTATION_LISTENERS[mutationName].push(actionName)
    }
  }

  /**
   * Trigger a list of actions when the mutation is called
   *
   * @param {Object} options Options
   * @param {String} options.on Mutation to observe
   * @param {Array<String>} options.dispatch Actions to dispatch
   */
  subscribes ({ on: mutationName, dispatch: actionNames }) {
    actionNames.forEach(actionName => this.subscribe({ on: mutationName, dispatch: actionName }))
  }

  /**
   * Stop triggering the action when the mutation is called
   *
   * @param {Object} options Options
   * @param {String} options.on Mutation to observe
   * @param {String} options.dispatch Action to dispatch
   */
  unsubscribe ({ on: mutationName, dispatch: actionName }) {
    if (this.MUTATION_LISTENERS[mutationName]) {
      this.MUTATION_LISTENERS[mutationName] = this.MUTATION_LISTENERS[mutationName]
        .filter(name => name !== actionName)
    }
  }

  getListeners (mutationName) {
    return this.MUTATION_LISTENERS[mutationName] || []
  }
}

function MediatorPlugin (mediator) {
  return store => store.subscribe(({ type: mutationName, payload }) => {
    mediator.getListeners(mutationName)
      .forEach(action => store.dispatch(action, payload))
  })
}

/**
 * Create a plugin mediator for Vuex store.
 * If mediator is passed, listeners options are ignored
 *
 * @param {Object} options Options for create the mediator
 * @param {Array<Object<on,dispatch>>} options.listeners List of all listeners. Optional
 * @param {Mediator} options.mediator Custom Mediator instance to use
 */
export default function createMediator ({ listeners, mediator }) {
  return MediatorPlugin(mediator || new Mediator(listeners))
}
