export default {
  menu: true,
  dark: false,
  documentMenu: false,
  collapsedMenu: false,
  sessionMenu: true,
  notification: {
    visible: false,
    message: '',
  },
  currentOperationTab: null,
  currentOperationProductionTab: null,
  currentPartsPerPageDoneList: 0,
  currentPartsPerPageTodoList: 0,
  currentPartsPerPageSuspendedList: 0,
  updateMeDialog: false,
  switchUserDialog: false,
  checkUserDialog: false,
}
