import vue from '../../../main'

export function showNotification ({ commit }, { success, message }) {
  commit('SHOW_NOTIFICATION', { success, message })
}

export function showSuccessNotification ({ commit }, message) {
  commit('SHOW_NOTIFICATION', { success: true, message })
}

export function showErrorNotification ({ commit }, message) {
  commit('SHOW_NOTIFICATION', { success: false, message })
}

export function hideNotification ({ commit }) {
  commit('HIDE_NOTIFICATION')
}

export function toggleMenu ({ commit }) {
  commit('TOGGLE_MENU')
}

export function toggleDark ({ commit }) {
  vue.$vuetify.theme.dark = !vue.$vuetify.theme.dark
  commit('TOGGLE_DARK')
}

export function toggleDocumentMenu ({ commit }) {
  commit('TOGGLE_DOCUMENT_MENU')
}

export function setMenu ({ commit, getters }, value) {
  if (getters.isMenuVisible !== value) {
    commit('SET_MENU', value)
  }
}

export function setDocumentMenu ({ commit, getters }, value) {
  if (getters.isDocumentMenuVisible !== value) {
    commit('SET_DOCUMENT_MENU', value)
  }
}

export function toggleCollapsedMenu ({ commit }) {
  commit('TOGGLE_COLLAPSED_MENU')
}

export function toggleSessionMenu ({ commit }) {
  commit('TOGGLE_SESSION_MENU')
}

export function setSessionMenu ({ commit, getters }, value) {
  if (getters.isSessionMenuVisible !== value) {
    commit('SET_SESSION_MENU', value)
  }
}

export function setCurrentOperationTab ({ commit }, value) {
  commit('SET_CURRENT_OPERATION_TAB', value)
}

export function setCurrentOperationProductionTab ({ commit }, value) {
  commit('SET_CURRENT_OPERATION_PRODUCTION_TAB', value)
}

export function setUpdateMeDialog ({ commit }, value) {
  commit('SET_UPDATE_ME_DIALOG', value)
}

export function setSwitchUserDialog ({ commit }, value) {
  commit('SET_SWITCH_USER_DIALOG', value)
}

export function setCheckUserDialog ({ commit }, value) {
  commit('SET_CHECK_USER_DIALOG', value)
}

export function setCurrentPartsPerPageDoneList ({ commit }, value) {
  commit('SET_CURRENT_PARTS_PER_PAGE_DONE_LIST', value)
}

export function setCurrentPartsPerPageTodoList ({ commit }, value) {
  commit('SET_CURRENT_PARTS_PER_PAGE_TODO_LIST', value)
}

export function setCurrentPartsPerPageSuspendedList ({ commit }, value) {
  commit('SET_CURRENT_PARTS_PER_PAGE_SUSPENDED_LIST', value)
}
