import Vue from 'vue'
import App from 'src/App'
import router from 'src/router'
import store from 'src/store'
import vuetify from 'src/plugins/vuetify'
import 'src/plugins/moment'
import 'src/plugins/v-mask'
import '@babel/polyfill'
import Config from 'src/config.json'

Vue.config.productionTip = false
Vue.prototype.$config = Config

const vueInstance = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

export default vueInstance
